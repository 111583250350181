export const data = [
  {
    id: 1,
    title: 'Health Boss APP',
    desc: 'Health Boss App is an app where you can track your meals and exercise to improve your health. I assumed the role as the front-end developer responsible for crafting the visual aesthetics and overall appearance of our applicationmeticulously tailoring it to align with our specific requirements.',
    img: 'assets/healthboss.jpg',
    github: 'None',
    demo: 'https://health-boss.vercel.app/',
    hastags: [
      '#NextJs ',
      '#Teamwork ',
      '#Tailwinds ',
      '#Mongoose ',
      '#Cypress ',
      '#Fullstack ',
      '#Figma',
    ],
  },

  {
    id: 2,
    title: 'Full stack Sayxiis',
    desc: 'App about photos for who like ONLY photos and competions, this app has mobile version and desktop, React.js for the front-end, delivering a dynamic and responsive user interface, PostgreSQL and Express for the back-end, ensuring robust data management and server-side functionality, Vanilla CSS, tailored with precision, to craft a visually appealing and distinctive aesthetic  ',
    img: 'assets/sayxiis.png',
    github: 'https://github.com/ciisiq/sayxiis',
    demo: 'None',
    hastags: [
      '#Reactjs ',
      '#Figma ',
      '#CSS ',
      '#Fullstack ',
      '#Express ',
      '#Postgres',
    ],
  },

  {
    id: 3,
    title: 'Fullstack Dad Jokes',
    desc: 'App where you can genarete random quotes from API and save the ones you like in the database',
    img: 'assets/dadjokes.png',
    github: 'https://github.com/ciisiq/dad-jokes-express-mongoose',
    demo: 'None',
    hastags: [
      '#Angular ',
      '#CSS ',
      '#Fullstack ',
      '#Mongoose ',
      '#Express ',
      '#Fullstack ',
      '#API',
    ],
  },

  {
    id: 4,
    title: 'Fullstack Studio Ghibli Film',
    desc: 'Full Stack Web application where you can see the list of films of studio ghibli, save the favorites one and share the list on the social media, using reactjs in the frontend and express in the backend with non-relational database mongoose',
    img: 'assets/ghiblit.png',
    github: 'https://github.com/ciisiq/studying-nextjs-frontend-ghibli',
    demo: 'https://studying-nodejs-ghibli.vercel.app/',
    hastags: [
      '#Express ',
      '#Nextjs ',
      '#Fullstack ',
      '#Mongoose ',
      'Tailwinds',
    ],
  },

  {
    id: 5,
    title: 'News Percayso',
    desc: 'Website for to search and see the news using an third-part api that fetch information about news from user input, used reacj.js in the frontend and Sass for the style',
    img: 'assets/news-app.png',
    github: 'https://github.com/ciisiq/news-app',
    demo: 'https://news-percayso-test.netlify.app/',
    hastags: [
      '#ReactJs ',
      '#API ',
      '#PostRequest ',
      '#Cypress ',
      '#Netifly ',
      '#SaSS ',
      '#Frontend',
    ],
  },

  {
    id: 6,
    title: 'Panda memory game',
    desc: 'A simple memory game, for to show DOM manipulation using only Vanilla JavaScrit and CSS',
    img: 'assets/page2.png',
    github: 'https://github.com/ciisiq/memory-game',
    demo: 'https://panda-memory-game.netlify.app/',
    hastags: ['#JavaScript ', '#DOM ', '#game ', '#CSS ', '#Grid'],
  },

  {
    id: 7,
    title: 'Job Tracker - School of Code',
    desc: 'A fullStack application built with Agile Team where main idea off this app is that other people could use to list their work without using tables or email, main main function in this project was deal with the styling, feat dark mode and did some of the testing in the backend routes and BDD frontend E2E',
    img: 'assets/job.png',
    github: 'https://github.com/SchoolOfCode/final-project-repo-bossa-nova',
    demo: 'https://bossanova.netlify.app/',
    hastags: [
      '#ReactJs ',
      '#Teamwork ',
      '#Tailwinds ',
      '#Jira ',
      '#Agile ',
      '#Cypress ',
      '#Fullstack',
    ],
  },
];
